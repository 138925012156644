import React from 'react'
import { AREA, REDIRECT_URL, AREA_EMAIL } from '../constants'

const Context = React.createContext()

export const AreaProvider = ({ children }) => {
  const area = AREA
  const apiUrl = REDIRECT_URL
  const feedbackEmail = AREA_EMAIL
  const isCian = false

  return (
    <Context.Provider value={{ area, apiUrl, isCian, feedbackEmail }}>
      {children}
    </Context.Provider>
  )
}

export default Context
