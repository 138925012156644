import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import analyticsContext, { AnalyticsProvider } from '../../context/ga'
import gaInit from '../../helpers/gaInit'

const GOOGLE_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScCfTnRMCvWP5QZpcLxCaWZdOrGXqhnTqaY62g1bHdQlXS2_A/viewform?entry.34121664'

const Redirect = () => {
  const { ClientId = '' } = useContext(analyticsContext)

  typeof window !== 'undefined' &&
    ClientId &&
    window.location.replace(`${GOOGLE_URL}=${ClientId}`)

  return null
}

const RequestAccess = () => {
  return (
    <AnalyticsProvider>
      <Helmet>
        <script>{gaInit()}</script>
      </Helmet>

      <Redirect />
    </AnalyticsProvider>
  )
}

export default RequestAccess
