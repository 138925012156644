import { useCallback } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage'
import differenceInMinutes from 'date-fns/differenceInMinutes'

export const UTM_HISTORY_LOCALSTORAGE_KEY = 'utmHistory'

const DEBOUNCE_IN_MINUTES = 1

export const useSavedUtm = () => {
  const [utmHistory, addUtm] = useLocalStorage(UTM_HISTORY_LOCALSTORAGE_KEY, [])

  const handleAddUtm = useCallback((rawUtm) => {
    if (rawUtm && Array.isArray(utmHistory)) {
      const lastDate = utmHistory.slice(-1)?.[0]?.createdAt

      if (
        !lastDate ||
        utmHistory.findIndex((entry) => entry.utm === rawUtm) === -1 ||
        differenceInMinutes(new Date(), new Date(lastDate)) >
          DEBOUNCE_IN_MINUTES
      ) {
        addUtm((utmHistory) => [
          ...utmHistory,
          {
            createdAt: new Date().toISOString(),
            utm: rawUtm,
          },
        ])
      }
    }
  }, [])

  return [utmHistory, handleAddUtm]
}
