import React, { useCallback, useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import { analytics } from '../helpers/googleAnalytics'
import { eraseCookie, getCookie, setCookie, utmCookieName } from './cookies'
import AreaContext from './area'
import { useSavedUtm } from './savedUtm'

const COOKIE_LIFETIME_DAYS = 30

const Context = React.createContext()

const getClientId = (onInit) => {
  const gaInit = () => {
    window.ga && window.ga('create', null, 'auto')

    const tracker = window.ga && window.ga.getAll()[0]
    onInit(tracker.get('clientId') || 'noclientid')
  }

  typeof window !== 'undefined' && window.ga && window.ga(gaInit)
}

const getRawUtm = () => {
  return (
    (typeof window !== 'undefined' && filterUtm(window.location.search)) || null
  )

  function filterUtm(search) {
    let query = queryString.parse(search)
    for (const key in query) {
      key.startsWith('utm')
        ? (query[key] = decodeURI(query[key]))
        : delete query[key]
    }
    return queryString.stringify(query, { encode: false })
  }
}

const getReferrer = () => {
  const hasBrowser =
    typeof window !== 'undefined' && typeof document !== 'undefined'

  if (hasBrowser) {
    const urlParts = document.referrer.split(/[/?#]/)
    const domain = urlParts[0] + '//' + urlParts[1]

    document.cookie =
      document.referrer && urlParts[1]
        ? `referrer=${domain}`
        : `referrer=${document.referrer}`

    return urlParts[1] ? domain : document.referrer
  }
}

const getReferral = () => {
  const hasBrowser =
    typeof window !== 'undefined' && typeof document !== 'undefined'

  const isReferral =
    location && location.href && location.href.includes('referral')
      ? location.href.split('/').filter((item) => item.includes('referral'))[0]
      : null

  if (hasBrowser && isReferral) {
    const referral = isReferral
      .split('?')[1]
      .split('&')
      .filter((item) => item.includes('referral'))[0]

    document.cookie = `${referral}`

    const referralId = referral.split('=')[1]

    return referralId
  }
}

const getCurator = () => {
  const hasBrowser =
    typeof window !== 'undefined' && typeof document !== 'undefined'

  const isCurator =
    location && location.href && location.href.includes('curator')
      ? location.href.split('/').filter((item) => item.includes('curator'))[0]
      : null

  if (hasBrowser && isCurator) {
    const curator = isCurator
      .split('?')[1]
      .split('&')
      .filter((item) => item.includes('curator'))[0]

    document.cookie = `${curator}`

    const curatorId = curator.split('=')[1]

    return curatorId
  }
}

export const useAnalytics = (data, deps = []) => {
  const { ClientId } = useContext(Context)

  const analyticsData =
    typeof data === 'function'
      ? (...props) => analytics({ ClientId, ...data(...props) })()
      : analytics({ ClientId, ...data })

  return useCallback(analyticsData, [...deps, ClientId])
}

export const useAnalyticsCtx = () => useContext(Context)

export const AnalyticsProvider = ({ children }) => {
  const [ClientId, setClientId] = useState(null)
  const [RawUtm, setRawUtm] = useState(null)
  const [referrer, setReferrer] = useState(null)
  const [referral, setReferral] = useState(null)
  const [curator, setCurator] = useState(null)
  const { area } = useContext(AreaContext) || {}
  const utm = utmCookieName(area)
  const [utmHistory, saveUtm] = useSavedUtm()

  const firstVisitCookie = getCookie(utm) || '{}'
  let firstVisitData = {}
  try {
    firstVisitData = JSON.parse(firstVisitCookie)
  } catch (e) {}

  useEffect(() => {
    getClientId((id) => setClientId(id))
  }, [])

  useEffect(() => {
    !RawUtm && setRawUtm(getRawUtm)
  }, [RawUtm])

  useEffect(() => {
    const rawUtm = getRawUtm()
    saveUtm(rawUtm)
  }, [])

  useEffect(() => {
    !referrer && setReferrer(getReferrer)
  }, [referrer])

  useEffect(() => {
    !referral && setReferral(getReferral)
  }, [referral])

  useEffect(() => {
    !curator && setCurator(getCurator)
  }, [curator])

  useEffect(() => {
    analytics({ ClientId, event: 'pageview', eventAction: '' })()
  }, [ClientId])

  useEffect(() => {
    if (!firstVisitData.RawUtm || RawUtm) {
      firstVisitData.RawUtm = RawUtm
      firstVisitData.referrer = referrer
      firstVisitData.referral = referral
      firstVisitData.curator = curator
      RawUtm && eraseCookie(utm)
      setCookie(utm, JSON.stringify({ RawUtm, referrer }), COOKIE_LIFETIME_DAYS)
    }
  }, [ClientId, RawUtm, referrer, referral, curator])

  return (
    <Context.Provider
      value={{
        ClientId,
        RawUtm: firstVisitData.RawUtm || RawUtm,
        referrer: firstVisitData.referrer || referrer,
        referral: firstVisitData.referral || referral,
        curator: firstVisitData.curator || curator,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
