export const analytics =
  ({
    ClientId = '',
    event = 'eventGA',
    eventCategory = '',
    eventAction = 'click',
    eventLabel = '',
    clickText = '',
    page = '',
    specialty = '',
    experience = '',
    error = '',
    address = '',
    utm = '',
  }) =>
  () => {
    return (
      ClientId &&
      window.dataLayer &&
      window.dataLayer.push({
        ClientId,
        event,
        eventLabel,
        eventCategory,
        eventAction,
        clickText,
        page,
        specialty,
        experience,
        address,
        error,
        utm,
      })
    )
  }
