const isBrowser = () =>
  typeof window !== 'undefined' && typeof document !== 'undefined'

export const setCookie = (name, value, days) => {
  if (!isBrowser()) return
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie =
    name + '=' + (encodeURIComponent(value) || '') + expires + '; path=/'
}

export const getCookie = (name) => {
  if (!isBrowser()) return
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const eraseCookie = (name) => {
  if (!isBrowser()) return
  document.cookie = name + '=; Max-Age=-99999999;'
}

export const utmCookieName = (area) => (area ? `utm-${area}` : 'utm')
